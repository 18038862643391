<template>
  <div>
    <b-row>
      <b-col class="text-right">
        <b-button
            type="button"
            variant="primary"
            :to="{name: 'pageOfflineTrainingAdd'}"
            class="mb-2"
            v-if="userData.userGroupId === 5"
        >
          <feather-icon icon="PlusCircleIcon"/>
          {{ $t('general.btnAdd') }}
        </b-button>
      </b-col>
    </b-row>

    <b-overlay :show="isShowLoading">
      <b-card :title="$t('offlineTraining.list')">
        <!-- table -->
        <vue-good-table
            mode="remote"
            :columns="tableData.columns"
            :rows="tableData.rows"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="tableData.totalRecords"
            :isLoading.sync="tableData.isLoading"
            :pagination-options="{ enabled: true }"
        >
          <template
              slot="table-row"
              slot-scope="props"
          >
            <div v-if="props.column.field === 'offlineDatetimeStart'">
              {{ props.row.offlineDateStart }} {{ props.row.offlineTimeStart }} - {{ props.row.offlineTimeEnd }}
            </div>

            <div v-else-if="props.column.field === 'offlineApproveStatusName'">
              <b-badge :variant="statusColor(props.row.offlineApproveStatus)">{{ props.row.offlineApproveStatusName }}</b-badge>
            </div>

            <div v-else-if="props.column.field === 'action'" class="text-nowrap text-center">
              <b-button
                  type="button"
                  variant="success"
                  @click="approveData(props.row.offlineId)"
                  size="sm"
                  class="mr-1"
                  v-if="props.row.offlineApproveStatus === 'W' && userData.userGroupId === 4"
              >
                <feather-icon icon="CheckIcon"/>
              </b-button>
              <b-button
                  type="button"
                  variant="warning"
                  @click="disapproveData(props.row.offlineId)"
                  size="sm"
                  class="mr-1"
                  v-if="props.row.offlineApproveStatus === 'W' && userData.userGroupId === 4"
              >
                <feather-icon icon="XIcon"/>
              </b-button>
              <b-button
                  type="button"
                  variant="danger"
                  size="sm"
                  @click="deleteData(props.row.offlineId)"
                  v-if="props.row.offlineApproveStatus === 'W' && userData.companyId === props.row.companyId"
              >
                <feather-icon icon="Trash2Icon"/>
              </b-button>
            </div>

            <div v-else>{{ props.formattedRow[props.column.field] }}</div>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('general.tableShowing') }} 1 {{ $t('general.tableTo') }}
            </span>
                <b-form-select
                    v-model="tableData.pageLength"
                    :options="tableData.pageLengthOption"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> {{ $t('general.tableOf') }} {{ props.total }} {{ $t('general.tableEntries') }} </span>
              </div>
              <div>
                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="tableData.pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
  </div>

</template>

<script>
import {BRow, BCol, BCard, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BButton, BForm, BOverlay, BBadge} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import tableMixins from "@/mixins/tableMixins"
import {OfflineTrainingService} from "@/services"

const offlineTrainingService = new OfflineTrainingService()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    BBadge,
    VueGoodTable
  },
  mixins: [tableMixins],
  created() {
    this.tableData.columns = [
      {
        label: this.$t('offlineTraining.bookingDate'),
        field: 'offlineDatetimeStart',
        width: '20%',
        sortable: false
      }, {
        label: this.$t('offlineTraining.participant'),
        field: 'offlineParticipant',
        width: '20%',
        sortable: false
      }, {
        label: this.$t('offlineTraining.approveStatus'),
        field: 'offlineApproveStatusName',
        width: '10%',
        sortable: false
      }, {
        label: '',
        field: 'action',
        width: '10%',
        sortable: false
      },
    ]

    if(this.userData.userGroupId === 4) {
      this.tableData.columns.unshift({
        label: this.$t('master.company.name'),
        field: 'companyName',
        width: '20%',
        sortable: false
      })
    }

    if(this.$route.query.bookingId) {
      this.search.bookingId = this.$route.query.bookingId
      this.onColumnFilter(this.search)
    } else {
      this.listData()
    }
  },
  data: () => ({
    isShowLoading: true,
    pk: '',
    search: {
      bookingId: '',
    },
    userData: JSON.parse(localStorage.getItem('userData')),
  }),
  computed: {},
  watch: {
    tableChange() {
      this.listData()
    }
  },
  methods: {
    listData() {
      this.getTableQueryString()
      offlineTrainingService.listData(this.tableQueryString).then(queryResult => {
        let {isSuccess, data} = queryResult
        if (isSuccess) {
          this.tableData.rows = data.results
          this.tableData.totalRecords = data.totalRow
        } else {
          this.$store.commit('main/setToastError', data)
        }
        this.isShowLoading = false
      })
    },
    searchData() {
      this.onColumnFilter(this.search)
    },
    approveData(id) {
      this.$bvModal
          .msgBoxConfirm(this.$t('general.alertApprove'), {
            title: this.$t('general.deleteTitle'),
            okVariant: 'primary',
            okTitle: this.$t('general.deleteConfirm'),
            cancelTitle: this.$t('general.deleteCancel'),
            cancelVariant: 'outline-secondary',
          })
          .then(value => {
            if (value) {
              this.saveApproval(id, 'Y')
            }
          })
    },
    disapproveData(id) {
      this.$bvModal
          .msgBoxConfirm(this.$t('general.alertDisapprove'), {
            title: this.$t('general.deleteTitle'),
            okVariant: 'primary',
            okTitle: this.$t('general.deleteConfirm'),
            cancelTitle: this.$t('general.deleteCancel'),
            cancelVariant: 'outline-secondary',
          })
          .then(value => {
            if (value) {
              this.saveApproval(id, 'N')
            }
          })
    },
    saveApproval(id, value) {
      offlineTrainingService
          .approveData(id, {approveStatus: value})
          .then(({isSuccess, data}) => {
            if (isSuccess) {
              this.isShowLoading = true
              this.listData()
              this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
            } else {
              this.$store.commit('main/setToastError', data)
            }
          })
    },
    deleteData(id) {
      this.$bvModal
          .msgBoxConfirm(this.$t('general.deleteDescription'), {
            title: this.$t('general.deleteTitle'),
            okVariant: 'primary',
            okTitle: this.$t('general.deleteConfirm'),
            cancelTitle: this.$t('general.deleteCancel'),
            cancelVariant: 'outline-secondary',
          })
          .then(async value => {
            if (value) {
              let queryResult = await offlineTrainingService.deleteData(id)
              let {isSuccess, data} = queryResult
              if (isSuccess) {
                this.isShowLoading = true
                this.listData()
                this.$store.commit('main/setToastSuccess', {title: 'Delete success', text: ''})
              } else {
                this.$store.commit('main/setToastError', data)
              }
            }
          })
    },
    statusColor(statusId) {
      if (statusId === 'Y') {
        return 'primary'
      } else if (statusId === 'C') {
        return 'secondary'
      } else if (statusId === 'N') {
        return 'danger'
      } else if (statusId === 'W') {
        return 'warning'
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

